import '../vendor/bootstrap.scss'
import './index.scss'
import $ from 'jquery'
import '../vendor/bootstrap'
import { setUpAuthModals } from '../components/auth-modals'
import { setUpFooter } from '../components/footer'
import { setUpHeaderButtons } from '../components/header'

$(function () {
  setUpHeaderButtons()
  setUpAuthModals()
  setUpFooter()

  $('*[data-slide-to-signup]').on('click', function () {
    const backUrl = $(this).data('signupBackUrl')
    $('#home_signup_form input[name="backUrl"]').val(backUrl)
    $('html,body').animate(
      {
        scrollTop: $('.signup-title').offset().top
      },
      1200
    )
  })
  const $nav = $('.navbar')
  $('button#btn_toggler').on('click', function () {
    if (!$('#navbarContent').is(':visible')) {
      $nav.addClass('navbar-expanded')
      $('#signup-navbar').css('position', 'initial')
    } else {
      $nav.removeClass('navbar-expanded')
      $('#signup-navbar').css('position', 'absolute')
    }
  })

  $('*[data-input="label"]').on('click', function () {
    $(this).next('input').focus()
  })

  $('.cta-header-btn').on('click', function () {
    const backUrl = $(this).data('signupBackUrl')
    $('.btn-social-signup').each(function () {
      let href = $(this).attr('href').split('backUrl=')
      href = href[0] + 'backUrl=' + backUrl
      $(this).attr('href', href)
    })
  })
})
