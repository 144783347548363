import $ from 'jquery'

export function setUpHeaderButtons() {
  $(window.document).on('click', '.js-login-link', function (e) {
    e.preventDefault()
    $('.js-signup-container').fadeOut('slow', function () {
      $('.js-signup-container').addClass('d-none')
      $('.js-signin-container').fadeIn().removeClass('d-none')
    })
  })

  $('body').on('click', '.js-signup-link', function (e) {
    e.preventDefault()
    $('.js-signin-container').fadeOut('slow', () => {
      $('.js-signin-container').addClass('d-none')
      $('.js-signup-container').fadeIn().removeClass('d-none')
    })
  })
}
