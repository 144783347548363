import 'owl.carousel'
import $ from 'jquery'
import GhostContentAPI from '@tryghost/content-api'

const maxMobileWidth = 576

function collapseCategories() {
  $('ul[data-expanded]').each(function (i, el) {
    const $caret = $(el).parent().find('.caret')
    $(el)
      .data('expanded', false)
      .animate({ height: 0, opacity: 0 }, 300)
      .css({ pointerEvents: 'none' })
    $caret.removeClass('fa-caret-up').addClass('fa-caret-down')
  })
}

function expandCategories() {
  $('ul[data-expanded]').each(function (i, el) {
    const $caret = $(el).parent().find('.caret')
    $(el)
      .data('expanded', true)
      .animate({ height: '100%', opacity: 1 }, 300)
      .removeAttr('style')
    $caret.removeClass('fa-caret-down').addClass('fa-caret-up')
  })
}

function updateCollapsesOnResize() {
  let $initialWidth = $(window).width()

  if ($(window).width() < maxMobileWidth) {
    collapseCategories()
  }

  $(window).on('resize', function (e) {
    const $windowWidth = $(e.target).width()
    const widthChange = $windowWidth !== $initialWidth
    if (widthChange) {
      if ($windowWidth >= maxMobileWidth) {
        expandCategories()
      } else {
        collapseCategories()
      }
      $initialWidth = $windowWidth
    }
  })
}

function updateCollapsesOnClick() {
  $(document).on('click', '.js-category', function (el) {
    if ($(window).width() >= maxMobileWidth) {
      return
    }
    const $targetId = $(el.currentTarget).data('self')
    const $target = $('#' + $targetId)
    const isExpanded = $target.data().expanded
    const $caret = $target.parent().find('.caret')

    if (isExpanded) {
      $caret.removeClass('fa-caret-up').addClass('fa-caret-down')
      $target
        .animate({ height: 0, opacity: 0, pointerEvents: 'none' }, 300)
        .css({ pointerEvents: 'none' })
    } else {
      $caret.removeClass('fa-caret-down').addClass('fa-caret-up')
      $target.animate({ height: '100%', opacity: 1 }, 300).removeAttr('style')
    }
    $target.data('expanded', !isExpanded)
  })
}

function configOwlCarousel() {
  $('#especialistas-carousel')
    .owlCarousel({
      center: true,
      lazyLoad: true,
      loop: true,
      margin: 10,
      nav: true,
      responsive: {
        0: {
          items: 1
        },
        320: {
          items: 3
        },
        768: {
          items: 7
        }
      }
    })
    .on('changed.owl.carousel', function () {
      setTimeout(function () {
        $('.owl-first-or-last').removeClass('owl-first-or-last')
        $('.owl-item.active:first').addClass('owl-first-or-last')
        $('.owl-item.active:last').addClass('owl-first-or-last')
      }, 1)
    })
  $('.owl-item.active:first').addClass('owl-first-or-last')
  $('.owl-item.active:last').addClass('owl-first-or-last')
  $('.owl-nav .owl-prev').html(
    '<i class="fa fa-chevron-circle-left fa-3x"></i>'
  )
  $('.owl-nav .owl-next').html(
    '<i class="fa fa-chevron-circle-right fa-3x"></i>'
  )
}

function loadBlogCategories() {
  const api = new GhostContentAPI({
    url: 'https://portaldotrader.com.br',
    key: 'a5112e1e5ab61289d12c271053',
    version: 'v3'
  })

  api.tags.browse({ limit: 'all' }).then(function (tags) {
    const blogCategories = tags.reduce(
      (acc, tag) =>
        acc + `<li><a href="${tag.url}" target="_blank">${tag.name}</a></li>`,
      ''
    )
    const html =
      '<li><a href="/blog/" target="_blank"><strong>Home do Blog</strong></a></li>' +
      blogCategories
    $('#category-blog').append(html)
  })
}

export function setUpFooter() {
  configOwlCarousel()
  loadBlogCategories()
  updateCollapsesOnResize()
  updateCollapsesOnClick()
}
