import $ from 'jquery'

const errorLogin = window.errorLogin
const errorSignup = window.errorSignup

export function setUpAuthModals() {
  if (errorLogin) {
    $('#modal_login').modal('show')
  }
  if (errorSignup) {
    $('#modal_signup').modal('show')
  }
  $('.modal-auth .open-modal').click(function () {
    const $closeModal = $(`#${$(this).data().closeModal}`)
    $closeModal.modal('hide').data('openModal', $(this).data().openModal)
  })
  $('.modal-auth').on('show.bs.modal', function () {
    $(this).data('openModal', null)
  })
  $('.modal-auth').on('hidden.bs.modal', function () {
    const openModal = $(this).data().openModal
    if (openModal) {
      $('#' + openModal).modal('show')
    }
  })
}
